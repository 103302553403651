import React, {lazy, Suspense} from 'react';
import {Backdrop, CircularProgress} from "@mui/material";

/**
 * Lazily load the mentioned component which resides in the page directory
 * This method will be used in routes so that the files are loaded only
 * When users are on that route
 */
const FallBack= () =>(
    <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={true}
    >
        <CircularProgress color="inherit"/>
    </Backdrop>
)
export function lazyLoadRoutes(componentName: string) {
    
    const LazyElement = lazy(() => import(`${componentName}.tsx`));

    // Wrapping around the suspense component is mandatory
    return (
        <Suspense  fallback={<FallBack/>}>
            <LazyElement/>
        </Suspense>
    );
}