import axios from "axios";
import authHeader from "./authHeader";
import {CreateFormData} from "../hooks/CreateFormData";

const endPoint = process.env.REACT_APP_API_URL;

export interface webContentHeader {
    url: string
}

export interface wordDocHeader {
    file: File
}

export const fetchWebContent = async (data: webContentHeader) => {
    try {
        return axios.post(`${endPoint}/api/fetch-webcontent`,
            data,
            {headers: authHeader()}
        )
    } catch (e: any) {

        return e?.response

    }

}


export const getWordDoc = async (data: wordDocHeader) => {

    try {

        // const authToken = JSON.parse(localStorage.getItem("authToken")!) || null
        return axios.post(`${endPoint}/api/copy-doc-content`,
            CreateFormData(data),
            {
                headers: {
                    // 'Authorization': `Token ${authToken.key}`,
                    "Content-Type": "multipart/form-data",
                }
            }
        )
    } catch (e: any) {

        return e?.response

    }


}

export const fetchGoogleDoc = async (payload: webContentHeader)=>{
    try {
        return axios.post(`${endPoint}/api/copy-google-doc`,
            payload,
            {headers: authHeader()}
        )
    } catch (e: any) {

        return e?.response

    }
}







