import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import pdfService from "../../services/pdf.services";
import { PDFData, SCHEMA } from "../../interfaces/interfaces";

export const createBlankPdf = createAsyncThunk(
  "pdf/createBlank",
  async (
    pdfData: {
      owner?: number;
      title: string;
      author: string;
      subject: string;
      keywords: string;
      schema: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await pdfService.createPdfData(pdfData);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const generatePdf = createAsyncThunk(
  "pdf/generatePdf",
  async (data: string, thunkAPI) => {
    try {
      const pdfDataUri = await pdfService.generatePdf(data);
      return pdfDataUri.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUserPdfs = createAsyncThunk(
  "pdf/getUserPdfs",
  async (userId: number, thunkAPI) => {
    try {
      const response = await pdfService.getUserPdfs(userId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPdf = createAsyncThunk(
  "pdf/getPdf",
  async (id: string, thunkAPI) => {
    try {
      const pdfData = await pdfService.getPdfData(id);
      return pdfData.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updatePdf = createAsyncThunk(
  "pdf/updatePdf",
  async (
    data: { id: string; schema: string; html: string | undefined },
    thunkAPI
  ) => {
    try {
      const pdfData = await pdfService.updatePdfData(data);
      return pdfData.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState: {
  createdPdf: PDFData;
  createPdfLoading: boolean;
  updatePdfLoading: boolean;
  pdfData: PDFData;
  getPdfLoading: boolean;
  getUserPdfsLoading: boolean;
  userPdfs: {
    id: string;
    owner: number;
    title: string;
    author: string;
    subject: string;
    html: string;
    schema: string;
    keywords: string;
    pdfDataUri: string;
    coverPhotoUri: string;
    creationDate: Date;
    modificationDate: Date;
  }[];
} = {
  createdPdf: {} as any,
  createPdfLoading: false,
  updatePdfLoading: false,
  pdfData: {} as any,
  getPdfLoading: false,
  getUserPdfsLoading: false,
  userPdfs: [],
};

const pdfsSlice = createSlice({
  name: "pdfs",
  initialState,
  reducers: {
    updatePDF: (state, action)=>{
      state.pdfData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createBlankPdf.fulfilled, (state, action) => {
      state.createPdfLoading = false;
      state.createdPdf = action.payload;
    });
    builder.addCase(createBlankPdf.rejected, (state, action) => {
      state.createPdfLoading = false;
    });
    builder.addCase(createBlankPdf.pending, (state, action) => {
      state.createPdfLoading = true;
    });
    builder.addCase(updatePdf.fulfilled, (state, action) => {
      state.updatePdfLoading = false;
    });
    builder.addCase(updatePdf.rejected, (state, action) => {
      state.updatePdfLoading = false;
    });
    builder.addCase(updatePdf.pending, (state, action) => {
      state.updatePdfLoading = true;
    });
    builder.addCase(getPdf.fulfilled, (state, action) => {
      state.getPdfLoading = false;
      state.pdfData = action.payload;
    });
    builder.addCase(getPdf.rejected, (state, action) => {
      state.getPdfLoading = false;
    });
    builder.addCase(getPdf.pending, (state, action) => {
      state.getPdfLoading = true;
    });
    builder.addCase(getUserPdfs.fulfilled, (state, action) => {
      state.getUserPdfsLoading = false;
      state.userPdfs = action.payload;
    });
    builder.addCase(getUserPdfs.rejected, (state, action) => {
      state.getUserPdfsLoading = false;
    });
    builder.addCase(getUserPdfs.pending, (state, action) => {
      state.getUserPdfsLoading = true;
    });
  },
});

export const {  updatePDF } = pdfsSlice.actions;
const { reducer } = pdfsSlice;

export default reducer;
