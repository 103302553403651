import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../../services/auth.service";
import { setInputError } from "../inputErrorSlice";
import { setMessage } from "../messageSlice";
import { getUser } from "./userSlice";

export const register = createAsyncThunk(
  "auth/register",
  async ({ email, username, password1, password2 }: any, thunkAPI) => {
    try {
      const response = await authService.register(
        email,
        username,
        password1,
        password2
      );
      thunkAPI.dispatch(setMessage(response.data.message));
      window.location.href = "/login";
      return response.data;
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      thunkAPI.dispatch(setInputError(error.response && error.response.data));
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }: any, thunkAPI) => {
    try {
      const data = await authService.login(username, password);
      const expirationDate = new Date(new Date().getTime() + 3600 * 1000 * 160);
      localStorage.setItem("expirationDate", JSON.stringify(expirationDate));
      thunkAPI.dispatch(
        checkAuthTimeout(expirationDate.getTime() - new Date().getTime())
      );
      //@ts-ignore
      thunkAPI.dispatch(getUser({}));
      return data;
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.non_field_errors) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async ({ empty }: any, thunkAPI) => {
    try {
      const data = await authService.logout();
      localStorage.clear();
      return data;
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.non_field_errors) ||
        error.message ||
        error.toString();
      console.log(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const checkAuthTimeout = (expirationDate: any) => {
  return (dispatch: any) => {
    setTimeout(() => {
      dispatch(logout({}));
    }, expirationDate);
  };
};

export const authCheckState = createAsyncThunk(
  "auth/checkState",
  async ({ empty }: any, thunkAPI) => {
    const authToken = localStorage.getItem("authToken")!;
    if (authToken === null) {
      thunkAPI.dispatch(logout({}));
    } else {
      const localStorageDate = localStorage.getItem("expirationDate");
      if (!localStorageDate) {
        return null;
      }
      const expirationDate = new Date(JSON.parse(localStorageDate));
      if (expirationDate <= new Date()) {
        thunkAPI.dispatch(logout({}));
      } else {
        thunkAPI.dispatch(
          checkAuthTimeout(expirationDate.getTime() - new Date().getTime())
        );
      }
    }
  }
);

export interface AuthState {
  isLoggedIn: boolean;
  authToken: any;
  authLoginLoading: boolean;
  logoutLoading: boolean;
  signedUp: boolean;
  authPayload: any;
}

const initialState: AuthState = {
  isLoggedIn: !!localStorage.getItem("authToken"),
  authToken: localStorage.getItem("authToken"),
  authLoginLoading: false,
  logoutLoading: false,
  signedUp: false,
  authPayload: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogOutLoading(state, action) {
      state.logoutLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(register.fulfilled, (state, action) => {
      state.authLoginLoading = false;
      state.signedUp = true;
      state.authPayload = action.payload;
    });
    builder.addCase(register.rejected, (state, action) => {
      state.authLoginLoading = false;
    });
    builder.addCase(register.pending, (state, action) => {
      state.authLoginLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.authToken = JSON.parse(localStorage.getItem("authToken")!);
      state.authLoginLoading = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.authLoginLoading = false;
    });
    builder.addCase(login.pending, (state, action) => {
      state.isLoggedIn = false;
      state.authLoginLoading = true;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.isLoggedIn = false;
      state.authToken = null;
    });
    builder.addCase(logout.pending, (state, action) => {
      state.logoutLoading = true;
    });
  },
});

export const { setLogOutLoading } = authSlice.actions;
const { reducer } = authSlice;

export default reducer;
