import {createAsyncThunk, createReducer, createSlice, createAction} from "@reduxjs/toolkit";
import {createRef, RefObject, useRef} from "react";
import userService from "../../services/user.service";
import UserService from "../../services/user.service";

interface pageInterface {
    page: number,
    ref: RefObject<any>,
    elements: Array<object>
}

export const bookDetails = createAsyncThunk(
    'ebookSlice/bookDetails',
    async (payload: { book_id: string | number }, thunkApi) => {
        try {
            return userService.fetchBookDetails(payload)
                .then(response => response?.data || {})
        } catch (e) {
            // @ts-ignore
            return e?.response

        }


    }
)

export const coverDetails = createAsyncThunk(
    'ebookSlice/coverDetails',
    async (payload: { book_id: string | number }, thunkAPI) => {
        try {
            return userService.getCoverDetails(payload)
                .then(response => response?.data || {})
        } catch (e) {
            //@ts-ignore
            return e?.response
        }
    }
)

export const getFlipBookDetails = createAsyncThunk(
    'ebookSlice/getFlipBookDetails',
    async (payload:{id:number|string;}, thunkAPI)=>{
        try {
            return userService.flipBookDetails(payload)
                .then(response=>response?.data || {})

        } catch (e) {
            //@ts-ignore
            return e?.response
        }
    }
)

export const getUserFlipBooks = createAsyncThunk(
    'ebookSlice/getUserFlipBooks',
    async (thunkAPI)=>{
        try {
            return userService.userFlipBooks()
                .then(response=>response?.data || {})

        } catch (e) {
            //@ts-ignore
            return e?.response
        }
    }
)

export const getTemplates = createAsyncThunk(
    'ebookSlice/getTemplates',
    async (thunkAPI) => {
        return UserService.fetchTemplates()
            .then(response => response.data)
    }
)

export const fetchDashboard = createAsyncThunk(
    'ebookSlice/fetchDashboard',
    async (thunkAPI)=>{
        return await UserService.fetchDashboardDetails()
            .then(response=>response.data)
    }
)


interface dashboardType{
    books: number | string ;
    templates: number | string ;
    cover: number | string ;
    flipbook:number| string;
}
const eBookSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(bookDetails.fulfilled, (state, action) => {
            if (action?.payload?.status) {

                state.bookContent = action?.payload?.data
                // console.log(state.ebook.length, action?.payload.data.content !== null)
                if (action?.payload.data.content !== null) {
                    state.ebook = JSON.parse(action?.payload.data.content)

                }
            }

        })
        builder.addCase(coverDetails.fulfilled, (state, action) => {
            if (action?.payload?.status) state.cover = action?.payload?.data
            else state.cover = {}
        })
        builder.addCase(getTemplates.fulfilled, (state, action) => {
            state.templates = action.payload?.data
        })
        builder.addCase(fetchDashboard.fulfilled, (state, action)=>{
            state.dashboard = action.payload.data
        })
        builder.addCase(getFlipBookDetails.fulfilled, (state, action)=>{
            state.flipBookDetails= action.payload?.data
        })

        builder.addCase(getUserFlipBooks.fulfilled, (state, action)=>{
            state.userFlipBooks= action.payload?.data
        })
    },
    name: 'eBookSlice',
    reducers: {
        updateBook: (state, action) => {
            state.ebook = action.payload
        },
        updateSelectedTemplate: (state, action) => {
            state.selectedTemplate = action.payload
        },
        updateDashboard: (state, action)=>{
            state.dashboard = action.payload.data
        },
        updateFlipBook :(state, action)=>{
            state.flipBookDetails=action.payload
        }

    },
    initialState: {
        ebook: [],
        bookContent: {},
        cover: {},
        templates: <Array<{ id: number; image: string; content: string; updated_at:string;created_at:string; }>>[],
        selectedTemplate: '',
        dashboard: <dashboardType>{},
        flipBookDetails:{},
        userFlipBooks:''

    },

})

export const {updateBook, updateSelectedTemplate, updateFlipBook} = eBookSlice.actions


export default eBookSlice.reducer