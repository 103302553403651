import {combineReducers, configureStore} from "@reduxjs/toolkit";
import undoable from "redux-undo";
import pdfReducer from "../features/pdf/pdfsSlice";
import pageReducer from "../features/page/pageSlice";
import schemaReducer from "../features/schema/schemaSlice";
import authReducer from "../features/auth/authSlice";
import userReducer from "../features/auth/userSlice";
import messageReducer from "../features/messageSlice";
import inputErrorReducer from "../features/inputErrorSlice";
import crawlerReducer from '../features/crawler/crawlerSlice'
import eBookReducer from '../features/ebook/eBookSlice'
import adminReducer from '../features/admin/adminSlice'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";
import localforage from 'localforage';



const persistConfig = {
  key: 'root',
  storage: storage,
};

const reducers = combineReducers({
  auth: authReducer,
  user: userReducer,
  pdf: pdfReducer,
  schema: undoable(schemaReducer),
  page: pageReducer,
  message: messageReducer,
  inputError: inputErrorReducer,
  crawler: crawlerReducer,
  ebook:undoable(eBookReducer, {
    limit: 5,
    ignoreInitialState: false,
    syncFilter: true,
    neverSkipReducer: true,
    debug:false


  }),
  admin:adminReducer
})

const _persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})
export const persistor = persistStore(store)



export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
