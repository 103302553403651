import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {fetchWebContent} from "../../services/urlService";


const fetchContent = createAsyncThunk(
    'crawlerSlice/fetchContent',
    async (data: any) => {
        try {
            return await fetchWebContent(data)

        } catch (e: any) {
            return e.response
        }
    }
)

const crawlerSlice = createSlice({
    name: 'crawlerSlice',
    initialState: {
        crawledContent: '',
        selectedMethod:'',

    },

    reducers: {

        updateCrawledContent: (state, action) => {
            state.crawledContent = action.payload
        },
        updateDocContent: (state, action) => {
            state.crawledContent = action.payload
        },
        updateGoogleDocContent: (state, action) => {
            state.crawledContent = action.payload
        },
        updateMethod: (state,action)=>{
            state.selectedMethod = action.payload
        }

    },
    extraReducers: {}
})


export const {updateCrawledContent, updateDocContent, updateGoogleDocContent, updateMethod} = crawlerSlice.actions

export default crawlerSlice.reducer


