import {Box, Button, Grid} from "@mui/material";
//@ts-ignore
import errorImage from '../../assets/img/500-error.svg'
import {ArrowBackIos, Refresh} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";

export default function Error() {
    const navigate = useNavigate()
    return (
        <Box sx={{fontFamily: `'Public Sans', sans-serif`}}>
            <br/>
            <br/>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={3} lg={3} md={3} className={'w3-hide-small'}></Grid>
                <Grid item xs={12} sm={6} lg={6} md={6}>

                    <div style={{textAlign: 'center'}}>
                        <img src={errorImage} style={{width: '100%', height: '500px'}} alt={'403 image'}/>
                        <br/>
                        <p>Sorry!!! Something just went wrong</p>
                        <br/>
                        <Button
                            disableElevation
                            sx={{textTransform:'capitalize', width:'150px'}}
                            onClick={()=>window.location.reload()}
                            startIcon={<Refresh/>} color={'primary'}
                            variant={'contained'}>
                           Refresh Now
                        </Button>
                    </div>

                </Grid>
            </Grid>
        </Box>
    )
}