
import {lazyLoadRoutes} from "./lazyRoutes";

export  const AdminRouteHandler = [
    {
        path:'admin/login',
        element:lazyLoadRoutes("./admin/auth/login")
    },
    {
        path:'admin/forgot-password',
        element:lazyLoadRoutes("./admin/auth/forgotPassword")
    },
    {
        path:'admin/account/',
        element:lazyLoadRoutes("./admin/index"),
        children:[
            {
                path:'dashboard',
                element:lazyLoadRoutes("./admin/dashboard/dashboard")
            },
            {
                path:'users',
                element:lazyLoadRoutes("./admin/users/users")
            },
            {
                path:'ebooks',
                element:lazyLoadRoutes("./admin/ebooks/ebooks")
            },
            {
                path:'flip-books',
                element:lazyLoadRoutes("./admin/flipbooks/flipbooks")
            },
            {
                path:'plans',
                element:lazyLoadRoutes("./admin/plans/plans")
            },
            {
                path:'templates',
                element:lazyLoadRoutes("./admin/templates/templates")
            },
            {
                path:'settings',
                element:lazyLoadRoutes("./admin/settings/settings")
            },
        ]
    },
    {
        path:'admin/editor/:randomStr/:bookID/:random',
        element: lazyLoadRoutes("./admin/templates/editor")
    }
]