import axios from "axios";
import authHeader from "./authHeader";

const endPoint = process.env.REACT_APP_API_URL;

const register = async (
  email: string,
  username: string,
  password1: string,
  password2: string
) => {
  return axios.post(`${endPoint}/api/register/`, {
    email,
    username,
    password1,
    password2,
  });
};

const login = async (username: string, password: string) => {
  return axios
    .post(`${endPoint}/rest-auth/login/`, { username, password })
    .then((response) => {
      if (response.data.key) {
        localStorage.setItem("authToken", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = async () => {
  return axios
    .post(`${endPoint}/rest-auth/logout/`, { headers: authHeader() })
    .then((response) => {
      localStorage.removeItem("authToken");
      return response.data;
    });
};

const authService = {
  register,
  login,
  logout,
};

export default authService;
