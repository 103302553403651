import {useRouteError, useRoutes} from 'react-router-dom';
import {lazyLoadRoutes} from "./lazyRoutes";
import Error from "./components/unauthorized/error";
import {AdminRouteHandler} from "./adminRouteHandler";
// import {ErrorPage} from "./routes";

export const CustomError = () => {
    const error = useRouteError() as any;
    console.log(error)
    return (
        <Error/>

    );
};

export function RouteHandler() {
    const routes = [
        {
            path: '/',
            name: 'index',
            element: lazyLoadRoutes('./home'),
            errorElement: <CustomError/>,
            children: [
                ...AdminRouteHandler,
                {
                    path: "dashboard/",
                    element: lazyLoadRoutes("./containers/Dashboard"),
                    children: [
                        {
                            path: "manage/",
                            children: [
                                {
                                    index: true,
                                    element: lazyLoadRoutes("./components/ManagePdf/ManagePdf"),
                                },
                                {
                                    path: "edit/:pdfId",
                                    element: lazyLoadRoutes("./components/PdfEditor/PdfEditor"),
                                },
                            ],
                        },
                        {
                            path: "create/",
                            element: lazyLoadRoutes("./components/CreatePdfPage/CreatePdf"),
                        },
                        {
                            path: '3d-cover/:randomStr/:bookID/:random',
                            element: lazyLoadRoutes("./components/cover/cover")
                        },
                        {
                            path: '3d-ecover-design/',
                            element: lazyLoadRoutes("./components/cover/generalCover")
                        },
                        {
                            path: 'flip-book-creator/',
                            element: lazyLoadRoutes("./components/flipbook/flipBookCreator")
                        },
                        {
                            path: 'settings/',
                            element: lazyLoadRoutes("./components/settings/settings")
                        },
                        {
                            path: 'ai-bot/',
                            element: lazyLoadRoutes("./components/chatGpt/ChatGPT")
                        },
                        {
                            path: 'done-for-you/',
                            element: lazyLoadRoutes("./components/dfy/market-place")
                        },
                        {
                            path: 'done-for-you/market-place/',
                            element: lazyLoadRoutes("./components/dfy/market-place")
                        },
                        {
                            path: 'done-for-you/offers/',
                            element: lazyLoadRoutes("./components/dfy/offers")
                        },
                        {
                            path: 'multiple-income/',
                            element: lazyLoadRoutes("./components/income/multipleIncome")
                        },
                        {
                            path: 'swift-profit/',
                            element: lazyLoadRoutes("./components/swift/swiftProfit")
                        },
                        {
                            path: 'traffic/',
                            element: lazyLoadRoutes("./components/traffic/traffic")
                        },
                        {
                            path: 'tutorials/',
                            element: lazyLoadRoutes("./components/tutorial/tutorial")
                        },
                        {
                            path: 'automation/',
                            element: lazyLoadRoutes("./components/automation/Automation")
                        },
                        {
                            path: 'stock-media/',
                            element: lazyLoadRoutes("./components/automation/automationNew")
                        },
                        {
                            path: 'agency/',
                            element: lazyLoadRoutes("./components/agency/agency")
                        },
                        {
                            path: 'franchise/',
                            element: lazyLoadRoutes("./components/reseller/reseller")
                        },
                        {
                            path: 'upgrade-plan/',
                            element: lazyLoadRoutes("./components/upgrade/upgradePlan")
                        },
                        {
                            path: 'market-place/',
                            element: lazyLoadRoutes("./components/marketplace/marketPlace")
                        },
                        {
                            path: 'scratch-ecover/',
                            element: lazyLoadRoutes("./components/eCoverDesign/eCoverDesign")
                        },

                    ],
                },

                {
                    path: 'editor/:randomStr/:bookID/:random',
                    element: lazyLoadRoutes("./components/editor/editor")

                },
                {
                    path: 'preview-ebook/:randomStr/:bookID/:random',
                    element: lazyLoadRoutes("./components/preview/previewEbook")

                },
                {
                    path: 'preview-template/:randomStr/:bookID/:random',
                    element: lazyLoadRoutes("./components/preview/previewTemplate")

                },
                {
                    path: 'flip-book/:randomStr/:bookID/:random',
                    element: lazyLoadRoutes("./components/flipbook/flipbook")
                },
                {
                    path: 'unauthorized',
                    element: lazyLoadRoutes("./components/unauthorized/unauthorized")
                },

                {
                    element: lazyLoadRoutes("./containers/Auth"),
                    children: [
                        {
                            index: true,
                            path: "/login",
                            element: lazyLoadRoutes("./components/Auth/Login/LoginNew"),
                        },
                        {
                            path: "/kreatereg-website",
                            element: lazyLoadRoutes("./components/Auth/Signup/Signup"),
                        },
                        {
                            path: "/reset-password/:str/:userid/:str",
                            element: lazyLoadRoutes("./components/Auth/resetpassword/resetPassword"),
                        },
                    ],
                },
                {
                    path: '/index',
                    element: lazyLoadRoutes("./containers")

                },
                {
                    path: '*',
                    element: lazyLoadRoutes("./components/unauthorized/notfound")
                }
            ],
        },

    ];

    return useRoutes(routes);
}