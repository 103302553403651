import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import './assets/css/w3.css'
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter, RouterProvider} from "react-router-dom";
// import {router} from "./routes";
import store from "./app/store";
import {persistor} from "./app/store";
import {Provider} from "react-redux";
import {createTheme, ThemeProvider} from "@mui/material";
import {Toaster} from "react-hot-toast";
import {PersistGate} from "redux-persist/integration/react";

import App from "./App";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
const theme = createTheme({
    palette: {
        primary: {
            main: '#1C2536',

        },
    },

})

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <Toaster position="top-right" reverseOrder={false}/>
                {/*<RouterProvider router={router}/>*/}
                <BrowserRouter>
                    <App/>
                </BrowserRouter>


            </ThemeProvider>

        </PersistGate>

    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
