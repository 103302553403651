import axios from "axios";
import authHeader from "./authHeader";
import { CreateFormData } from "../hooks/CreateFormData";

export const endPoint = process.env.REACT_APP_API_URL;

const getUser = async () => {
  return axios.get(`${endPoint}/rest-auth/user/`, {
    headers: authHeader(),
  });
};

const fetchUser = async () => {
  return axios.post(
    `${endPoint}/api/user-details`,
    {},
    {
      headers: authHeader(),
    }
  );
};

const createBook = async (payload: object) => {
  return axios.post(`${endPoint}/api/create-book`, payload, {
    headers: authHeader(),
  });
};

const fetchUserBooks = async () => {
  return axios.post(
    `${endPoint}/api/manage-books`,
    {},
    {
      headers: authHeader(),
    }
  );
};

const fetchUserPlans = async () => {
  return axios.post(
    `${endPoint}/api/user-plan`,
    {},
    {
      headers: authHeader(),
    }
  );
};

const fetchBookDetails = async (payload: { book_id: string | number }) => {
  return axios.post(`${endPoint}/api/book-details`, payload, {
    headers: authHeader(),
  });
};

const updateBookContent = async (payload: {
  book_id: string | number;
  content: any;
}) => {
  return axios.post(`${endPoint}/api/update-book`, payload, {
    headers: authHeader(),
  });
};

const getCoverDetails = async (payload: { book_id: string | number }) => {
  return axios.post(`${endPoint}/api/cover-details`, payload, {
    headers: authHeader(),
  });
};

const uploadCoverImage = async (payload: {
  book: string | number;
  image: File;
}) => {
  return axios.post(`${endPoint}/api/create-cover`, CreateFormData(payload), {
    headers: authHeader(),
  });
};

const uploadImage = async (payload: { book: string | number; image: File }) => {
  return axios.post(`${endPoint}/api/upload-image`, CreateFormData(payload), {
    headers: authHeader(),
  });
};

const fetchTemplates = async () => {
  return axios.post(
    `${endPoint}/api/fetch-templates`,
    {},
    {
      headers: authHeader(),
    }
  );
};

const deleteBook = async (payload: {
  book_id: string | number;
  images: Array<string> | any;
  cover: string;
  coverID: number | string;
}) => {
  return axios.post(`${endPoint}/api/delete-book`, payload, {
    headers: authHeader(),
  });
};

const fetchDashboardDetails = async () => {
  return axios.post(
    `${endPoint}/api/fetch-breakdown`,
    {},
    {
      headers: authHeader(),
    }
  );
};

const uploadFlipBook = async (payload: {
  file: File;
  user: number | string;
}) => {
  return axios.post(
    `${endPoint}/api/upload-flipbook`,
    CreateFormData(payload),
    {
      headers: authHeader(),
    }
  );
};

const deleteFlipBook = async (payload: { id: number | string }) => {
  return axios.post(`${endPoint}/api/delete-flipbook`, payload, {
    headers: authHeader(),
  });
};

const userFlipBooks = async () => {
  return axios.post(
    `${endPoint}/api/user-flipbook`,
    {},
    {
      headers: authHeader(),
    }
  );
};

const flipBookDetails = async (payload: { id: number | string }) => {
  return axios.post(`${endPoint}/api/flipbook-details`, payload, {
    headers: authHeader(),
  });
};

const changePassword = async (payload: {
  password: string;
  oldPassword: string;
}) => {
  return axios.post(`${endPoint}/api/update-password`, payload, {
    headers:
      localStorage.getItem("authToken") !== null
        ? authHeader()
        : {
            Authorization: `Token ${localStorage.getItem("adminToken")}`,
          },
  });
};

const updateProfile = async (payload: {
  username: string;
  firstname: string;
  lastname: string;
}) => {
  return axios.post(`${endPoint}/api/update-profile`, payload, {
    headers:
      localStorage.getItem("authToken") !== null
        ? authHeader()
        : {
            Authorization: `Token ${localStorage.getItem("adminToken")}`,
          },
  });
};

const logOut = async () => {
  return axios.post(
    `${endPoint}/api/logout`,
    {},
    {
      headers: authHeader(),
    }
  );
};

const getCredentials = async () => {
  return axios.post(
    `${endPoint}/api/get-credentials`,
    {},
    {
      headers: authHeader(),
    }
  );
};

const fetchAgency = async () => {
  return axios.post(
    `${endPoint}/api/user-agency`,
    {},
    {
      headers: authHeader(),
    }
  );
};

const updateCredentials = async (payload: { key: string }) => {
  return axios.post(`${endPoint}/api/update-credentials`, payload, {
    headers: authHeader(),
  });
};

const createAgencyUser = async (payload: {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  oto2: number;
}) => {
  return axios.post(`${endPoint}/api/agency-add-user`, payload, {
    headers: authHeader(),
  });
};
const editAgencyUser = async (payload: {
  userid: number;
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  oto2: number;
}) => {
  return axios.post(`${endPoint}/api/agency-edit-user`, payload, {
    headers: authHeader(),
  });
};

const createUserAutomation = async (payload: {
  name: string;
  email: string;
  flipbook_id: number;
}) => {
  return axios.post(`${endPoint}/api/submit-automation`, payload, {
    headers: authHeader(),
  });
};

const fetchAutomations = async () => {
  return axios.post(
    `${endPoint}/api/get-automation`,
    {},
    {
      headers: authHeader(),
    }
  );
};

const fetchDFY = async () => {
  return axios.post(
    `${endPoint}/api/admin-flipbooks`,
    {},
    {
      headers: authHeader(),
    }
  );
};

const sendAutomation = async (payload: {
  subject: string;
  message: string;
  emails: Array<string>;
}) => {
  return axios.post(`${endPoint}/api/send-automation-mail`, payload, {
    headers: authHeader(),
  });
};

const copyDFY = async (payload: { flipbook_id: number | string }) => {
  return axios.post(`${endPoint}/api/copy-flipbook`, payload, {
    headers: authHeader(),
  });
};

const resetPassword = async (payload: { email: string }) => {
  return axios.post(`${endPoint}/api/reset-password`, payload, {
    headers: authHeader(),
  });
};

const fetchPixaBay = async (url: string) => {
  return await axios.get(url);
};
const downloadPDF = async (payload: { html: string }) => {
  return await axios.post(`${endPoint}/api/download-pdf`, payload, {
    headers: authHeader(),
    responseType: "arraybuffer",
  });
};

const copyEBook = async (payload: {
  title: string;
  author: string;
  keyword: string | undefined;
  book_id: string;
}) => {
  return await axios.post(`${endPoint}/api/copy-book`, payload, {
    headers: authHeader(),
  });
};

const updateBookTitle = async (payload: {
    title: string;
    book_id:string
  }) => {
    return await axios.post(`${endPoint}/api/update-book-title`, payload, {
      headers: authHeader(),
    });
  };

  const fetchECoverTemplates = async () => {
    return axios.post(
      `${endPoint}/api/get-ecovers`,
      {},
      {
        headers: authHeader(),
      }
    );
  };

const userService = {
  getUser,
  fetchUser,
  createBook,
  fetchUserBooks,
  fetchBookDetails,
  updateBookContent,
  getCoverDetails,
  uploadCoverImage,
  uploadImage,
  fetchTemplates,
  deleteBook,
  fetchDashboardDetails,
  uploadFlipBook,
  deleteFlipBook,
  userFlipBooks,
  flipBookDetails,
  updateProfile,
  changePassword,
  logOut,
  getCredentials,
  updateCredentials,
  fetchUserPlans,
  fetchAgency,
  createAgencyUser,
  editAgencyUser,
  createUserAutomation,
  fetchAutomations,
  sendAutomation,
  fetchDFY,
  copyDFY,
  resetPassword,
  fetchPixaBay,
  downloadPDF,
  copyEBook,
  updateBookTitle,
  fetchECoverTemplates
};

export default userService;
