import {
    createSlice,
    // current
} from "@reduxjs/toolkit";
import {
    AlignPosition,
    SCHEMA,
    TextAlignment,
    Type,
} from "../../interfaces/interfaces";
import {NODE_DEFAULT_POSITION} from "../../const";
import {array} from "yup";

const initialState: {
    schema: SCHEMA;
    selectedNode: any;
    pages:Array<any>
} = {
    schema: [],
    selectedNode: {},
    pages:[]
};

const schemalice = createSlice({
    name: "schema",
    initialState,
    reducers: {
        loadSchema: (state, action) => {
            const newSchamas = [...action.payload]; //making a new Schamas;
            state.schema = newSchamas;
        },

        // Working...
        addNode: (state, action) => {
            switch (action.payload.type) {
                case "text":
                    state.schema.push({
                        page: action.payload.page,
                        id: action.payload.id,
                        type: "text" as Type,
                        content: "Text Here",
                        rotate: 0,
                        style: {
                            fontSize: 18,
                            fontFamily: "Helvetica",
                            fontWeight: "normal",
                            letterSpacing: 0,
                            color: "#000",
                            backgroundColor: "transparent",
                            display: "flex",
                            textAlign: "left" as TextAlignment,
                            alignItems: "start" as AlignPosition,
                            position: "absolute",
                            top: NODE_DEFAULT_POSITION.y,
                            left: NODE_DEFAULT_POSITION.x,
                        },
                    });

                    break;
                case "image":
                    state.schema.push({
                        page: action.payload.page,
                        id: action.payload.id,
                        type: "image" as Type,
                        content: action.payload.content,
                        rotate: 0,
                        style: {
                            fontSize: 0,
                            fontStyle: "",
                            letterSpacing: 0,
                            display: "flex",
                            textAlign: "left" as TextAlignment,
                            alignItems: "start" as AlignPosition,
                            justifyContent: "start" as AlignPosition,
                            height: 100,
                            width: 100,
                            position: "absolute",
                            top: NODE_DEFAULT_POSITION.y,
                            left: NODE_DEFAULT_POSITION.x,
                        },
                    });

                    break;
                case "rectangle":
                    state.schema.push({
                        page: action.payload.page,
                        id: action.payload.id,
                        type: "rectangle" as Type,
                        content: action.payload.content,
                        rotate: 0,
                        style: {
                            fontSize: 0,
                            fontStyle: "",
                            letterSpacing: 0,
                            display: "flex",
                            textAlign: "left" as TextAlignment,
                            alignItems: "start" as AlignPosition,
                            justifyContent: "start" as AlignPosition,
                            height: 50,
                            width: 100,
                            position: "absolute",
                            top: NODE_DEFAULT_POSITION.y,
                            left: NODE_DEFAULT_POSITION.x,
                            fill: "black",
                        },
                    });
                    break;
                case "circle":
                    state.schema.push({
                        page: action.payload.page,
                        id: action.payload.id,
                        type: "circle" as Type,
                        content: action.payload.content,
                        rotate: 0,
                        style: {
                            fontSize: 0,
                            fontStyle: "",
                            letterSpacing: 0,
                            display: "flex",
                            textAlign: "left" as TextAlignment,
                            alignItems: "start" as AlignPosition,
                            justifyContent: "start" as AlignPosition,
                            height: 100,
                            width: 100,
                            position: "absolute",
                            top: NODE_DEFAULT_POSITION.y,
                            left: NODE_DEFAULT_POSITION.x,
                            fill: "black",
                        },
                    });
                    break;
                default:
                    break;
            }
            // localStorage.setItem("schema", JSON.stringify(state.schema));
        },

        // Working
        removeNode: (state, action) => {
            const newSchamas = state.schema.filter(
                (schema) => schema.id !== action.payload
            );
            state.schema = newSchamas;
            // localStorage.setItem("schema", JSON.stringify(state.schema));
            state.selectedNode = {};
        },

        // Working...
        alterNode: (state, action) => {
            const index = state.schema.findIndex(
                (schema) => schema.id === action.payload.id
            ); //finding index of the item
            const newSchamas = [...state.schema]; //making a new Schamas
            newSchamas[index] = action.payload; //changing value in the new Schamas
            state.schema = newSchamas;
            // localStorage.setItem("schema", JSON.stringify(newSchamas));
        },

        setSelectedNode: (state, action) => {
            state.selectedNode = state.schema.find(
                (schema) => schema.id === action.payload
            );
        },
    },
    extraReducers: (builder) => {
    },
});

export const {loadSchema, addNode, removeNode, alterNode, setSelectedNode} =
    schemalice.actions;
const {reducer} = schemalice;

export default reducer;
